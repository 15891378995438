<template>
  <div>
    <div
      v-if="visibleBanner"
      class="ld-lw-connect-banner"
    >
      <v-layout
        justify-center
        align-center
        :style="{ 'background-color': color.background  }"
      >
        <span v-html="text"></span>
        <v-btn
          id="lw-connect-banner-connect"
          class="btn-connect ml-3"
          :color="color.actionColor"
          @click="handleConnect"
        >Link Account</v-btn>
        <v-btn
          id="lw-connect-banner-learnmore"
          flat
          dark
          :color="color.foreground"
          @click="handleLearnMore"
        >
          Learn more
        </v-btn>
      </v-layout>
    </div>
    <v-dialog
      v-if="visibleLearnMoreDialog"
      v-model="visibleLearnMoreDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <div class="close-btn-container mr-2">
          <v-btn
            class="icon-btn ma-0 pa-0"
            flat="flat"
            icon="icon"
            @click="handleLearnMore"
          >
            <v-icon
              class="ma-0 pa-0"
              size="20"
            >close</v-icon>
          </v-btn>
        </div>
        <v-layout
          class="dialog-container px-4"
          wrap
        >
          <v-flex class="mt-3 xs4 ld-icon">
            <v-img
              src="/static/img/ld-powered-lw-logo.svg"
              height="40"
              contain
            ></v-img>
          </v-flex>
          <v-flex class="mt-3 xs8 title">
            Connect your LionDesk account
          </v-flex>
          <v-flex class="body mt-3 xs12">
            <p class="description">
              As a LionDesk user with a  <a
                    href="https://www.lwolf.com/"
                    target="_blank"
                  >Lone Wolf</a> account, you can benefit from a more comprehensive set of tools by connecting your accounts. Here's how:
            </p>
          </v-flex>
          <v-flex class="body mt-2 xs12">
            <ol>
              <li><strong>Sign up for a Lone Wolf account:</strong> If you don't already have one, create a new Lone Wolf account.</li>
              <li><strong>Link your accounts:</strong> Once you have a Lone Wolf account, follow the on-screen instructions to link it to your existing LionDesk account.</li>
            </ol>
          </v-flex>
          <v-flex class="body mt-3 xs12">
            <span class="subtitle">Benefits of connecting your accounts:</span>
            <ul>
              <li><strong>One login for all:</strong> Access all Lone Wolf products with a single set of credentials.</li>
              <li><strong>Enhanced security:</strong> Benefit from state-of-the-art security measures to protect your sensitive data.</li>
              <li><strong>Full suite access:</strong> Unlock the entire suite of Lone Wolf products, including Cloud CMA, Boost, Spacio, Authentisign, and more.</li>
            </ul>
          </v-flex>
          <v-flex class="body mt-3 xs12">
            <span>That's it! Now you can enjoy the benefits of a unified platform and access all your Lone Wolf tools with just one login.</span>
          </v-flex>
          <v-flex class="xs12 my-4">
            <v-btn
              id="lw-connect-banner-setup"
              class="btn-action"
              color="primary"
              @click="handleConnect"
              data-cy="submit-button"
            >Connect your account</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="visibleEndFlowDialog"
      v-model="visibleEndFlowDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <ld-title-bar dark>
          Connected Lone Wolf Account
        </ld-title-bar>
        <v-card-text>
          <v-layout
            class="dialog-container"
            wrap
          >
            <v-flex class="body mt-3 xs12">
              <p class="description">
                You're all set!
              </p>
            </v-flex>
            <v-flex class="body mt-2 xs12">
              <p class="description">
                You can change your Lone Wolf profile by going to “Home” using the app switcher at the top left corner of the screen.
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="lw-connect-banner-end-dialog-close"
            flat
            color="primary"
            @click="handleCloseLastDialog"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { HC_PROPS } from 'lion-vue-src/_globals';
import { mapGetters } from 'vuex';
import LdTitleBar from '../LDTitleBar';

const TRIAL_STATUS_LIST = ['trial', 'trial_expired'];

const SSO_PLANS_LIST = [
  'plan-liondesk-CRMLS-monthly',
  'plan-liondesk-v2-CAR-monthly',
  'plan-liondesk-SDMLS-monthly',
  'plan-liondesk-REColorado-monthly',
  'plan-v2-liondesk-entitlement-hawaii-aor',
  'plan-liondesk-CCAR-monthly',
  'plan-liondesk-BayEast-monthly',
  'liondesk-Alaska_MLS-monthly',
  'plan-liondesk-real-comp-monthly',
  'plan-v2-liondesk-entitlement-nwok-aor',
  'plan-v2-liondesk-entitlement-wyoming-aor',
  'plan-v2-liondesk-entitlement-michric',
  'plan-v2-liondesk-entitlement-annarbor-bor',
  'plan-v2-liondesk-entitlement-willamette-mls',
  'plan-liondesk-promote-agent-bundle-monthly',
  'plan-liondesk-leadsplus-monthly',
];

export default {
  components: { LdTitleBar },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      visibleBanner: false,
      visibleLearnMoreDialog: false,
      visibleEndFlowDialog: false,
      color: {
        foreground: 'white',
        actionColor: '#A64951',
        background: '#F1D4D7',
      },
    };
  },
  mounted() {
    this.showBanner();
    this.showEndFlowDialog();
  },
  methods: {
    showBanner() {
      if (!this.userHasFeatureFlag('promote_lwa_creation')) {
        return;
      }
      if (!this.user) {
        return;
      }
      this.visibleBanner = this.allowedLWAConnection && !this.user.lwa_id;
      this.$emit('show', this.visibleBanner);
    },
    showEndFlowDialog() {
      if (!this.userHasFeatureFlag('promote_lwa_creation')) {
        return;
      }
      if (!this.user) {
        return;
      }

      const isPromoteLWAFlow = this.$route.query['plwa'] === 'connected';
      this.visibleEndFlowDialog = isPromoteLWAFlow && !this.visibleBanner && !!this.user.lwa_id;
      if (this.visibleEndFlowDialog) {
        this.$nextTick(() => {
          const ulMenu = document.querySelector('#lwt-unified-navbar__application-switcher-menu-toggle');
          if (ulMenu) ulMenu.focus();
        });
        
      }
    },
    getRedirectURL() {
      return `${HC_PROPS.ACCOUNTS_APP_URL}/connect-lone-wolf-account?ref=${encodeURIComponent(
        `${location.origin}?plwa=connected`,
      )}&state=connect`;
    },
    handleConnect() {
      window.location.href = this.getRedirectURL();
    },
    handleLearnMore() {
      this.visibleLearnMoreDialog = !this.visibleLearnMoreDialog;
    },
    handleCloseLastDialog() {
      this.visibleEndFlowDialog = false;
    }
  },
  computed: {
    ...mapGetters('userInformation', ['userHasFeatureFlag']),
    text() {
      return `LionDesk is part of the Lone Wolf ecosystem. Set up your Lone Wolf account and connect your LionDesk account.`;
    },
    allowedLWAConnection() {
      if (!this.user) {
        return false;
      }

      if (!this.user.subscriptionOverview) {
        return false;
      }

      const { subscriptionOverview } = this.user;

      const isTrial = TRIAL_STATUS_LIST.includes(subscriptionOverview.txt_status);
      if (isTrial) return false;

      const nonStandAloneAccount =
        subscriptionOverview.is_broker || subscriptionOverview.is_subaccount;
      if (nonStandAloneAccount) return false;

      const activeSubscription =
        subscriptionOverview.details && subscriptionOverview.details.txt_status === 'active';
      if (!activeSubscription) return false;

      const isSSOSubscription = subscriptionOverview.active_items.some(str =>
        SSO_PLANS_LIST.some(subStr => str.includes(subStr)),
      );
      return !isSSOSubscription;
    },
  },
  watch: {
    visibleBanner: {
      handler() {
        this.$emit('show', this.visibleBanner);
      },
    },
    user: {
      handler() {
        this.showBanner();
        this.showEndFlowDialog();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$banner-font-size: 16px;
.ld-lw-connect-banner {
  line-height: 1;
  height: 56px;
  color: white;
  font-weight: bold;
  font-size: $banner-font-size;
  width: 100%;
  padding: 6px 24px 2px;

  .layout {
    padding: 0 12px;
    height: 100%;
  }
  .divider {
    height: 30px;
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.27);
    margin: auto 0;
  }
  .v-btn {
    font-size: 14px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .btn-connect {
    font-weight: bold;
    color: white !important;
  }
}

.dialog-container {
  justify-content: center;
  text-align: center;
  .body {
    text-align: start;

    .description {
      font-size: 16px;
    }
  }

  .btn-action {
    color: white !important;
  }
}

.title {
  font-size: 20px !important;
  font-weight: bolder;
  text-align: left;
  padding-top: 16px;
}

.subtitle {
  font-weight: bold;
}

.close-btn-container {
  position: absolute;
  right: 0;
}
.ld-icon {
  margin-left: -56px;
}
</style>
