<template>
  <ld-persistent-banner
    :link="redirectUrl"
    :warningIcon="true"
    class="text-pre"
    v-if="visible"
    :error="true"
    :text="text"
    :buttonText="buttonText"
    :complete="isComplete"
  >
  </ld-persistent-banner>
</template>

<script>
import LdPersistentBanner from './LdPersistentBanner';
import { HC_PROPS } from 'lion-vue-src/_globals';
import { createNamespacedHelpers } from 'vuex';
import { LOW_BRAND_TEXT_LIMIT, LOW_STANDARD_BRAND } from 'lion-vue-src/config/brand'
const { mapState: mapAppLayoutState, mapActions: mapAppLayoutActions } = createNamespacedHelpers(
  'ldAppLayout',
);
export default {
  components: { LdPersistentBanner },
  props: {
    smsUsage: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapAppLayoutState([
      'registrationLowStandardInfo',
    ]),
    redirectUrl() {
      return `${HC_PROPS.PLATFORM_APP_URL}/a2p-standardbrand-registration`;
    },
    totalSmsCount() {
      return this.smsUsage.day_text_scheduled + this.smsUsage.day_text_sent;
    },
    text() {
      var textsLimit = this.isLowStandard ? this.formatNumberWithCommas(LOW_BRAND_TEXT_LIMIT) : '3,000'
      if(this.smsUsage.limit_level === 3) {
        return `You have exceeded your daily text limit. To ensure your business is not flagged as spam by the new regulation A2P 10DLC
         adopted by all major US wireless carriers, there is a limit of ${textsLimit} and you have reached ${this.totalSmsCount || 0} of text sent and scheduled for today.`
      } else {
        return `Your daily texts limit is about to be exceeded. To ensure your business is not flagged as spam by the new regulation A2P 10DLC
         adopted by all major US wireless carriers, there is a limit of ${textsLimit} and you have reached ${this.totalSmsCount || 0} of text sent and scheduled for today.`
      }
    },
    isVerified() {
      return this.user && this.user.email_is_verified;
    },
    smsLimitLevelReached() {
      return this.smsUsage.limit_level > 0;
    },
    buttonText() {
      return this.isLowStandard ? "UPGRADE BRAND" : "Register to standard";
    },
    isLowStandard() {
      return this.user && this.registrationLowStandardInfo.registration_type === LOW_STANDARD_BRAND;
    }
  },
  data() {
    return {
      visible: false,
      isComplete: false,
    };
  },
  watch: {
    smsUsage: {
      handler() {
        // show banner only if limit percentage has been reached
        this.visible = this.smsLimitLevelReached;
        this.showViaFeatureFlag();
        if (this.visible) {
          // notify parent whether or not this banner is visible
          this.$emit('show', this.visible);
        }
      }
    },
    user: {
      handler() {
        this.showViaFeatureFlag();
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    if (this.smsLimitLevelReached) {
      await this.getRegistrationLowStandard10DlcInfo();
    }
  },
  methods: {
    ...mapAppLayoutActions([
      'getRegistrationLowStandard10DlcInfo',
    ]),
    showViaFeatureFlag() {
      if (this.user && this.user.feature_flags.includes('show-exceeding-daily-text-banner')) {
          this.visible = true;
          this.$emit('show', this.visible);
        }
    },
    formatNumberWithCommas(number) {
      const numFormat = Intl.NumberFormat('en-US');
      return numFormat.format(number);
    },
  },
};
</script>
<style scoped lang="scss">
  .text-pre {
    white-space: pre-line;
    height: auto !important;
    padding: 10px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>
