import { render, staticRenderFns } from "./LdConnectLwAccountBanner.vue?vue&type=template&id=bf821b74&scoped=true"
import script from "./LdConnectLwAccountBanner.vue?vue&type=script&lang=js"
export * from "./LdConnectLwAccountBanner.vue?vue&type=script&lang=js"
import style0 from "./LdConnectLwAccountBanner.vue?vue&type=style&index=0&id=bf821b74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf821b74",
  null
  
)

export default component.exports